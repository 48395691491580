<template>
  <a-tree
    checkable
    :tree-data="updatedTreeData"
    v-model:expandedKeys="expandedKeys"
    v-model:selectedKeys="selectedKeys"
    v-model:checkedKeys="checkedKeys"
    :disabled="disabled"
    @check="onCheck"
    class="bg-transparent tree-data-custom"
  >
    <template #title="{ dataRef, expanded }">
      <span class="text-sm text-ems-gray200">{{ dataRef.name }}</span>
    </template>
  </a-tree>
</template>
<script setup>
import { computed, defineEmits, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const expandedKeys = ref(['0-0']);
const selectedKeys = ref([]);
const props = defineProps({
  treeData: {
    default: [],
  },
  selectedData: {
    default: [],
  },
  disabled: {
    default: false,
  },
});
const checkedKeys = computed(() => props.selectedData);
const emits = defineEmits(['onCheck', 'getInfoNode']);
const updatedTreeData = computed(() => {
  const outerParentNode = {
    name: t('user_group.all'),
    children: props.treeData,
  };
  return [outerParentNode];
});
const onCheck = (checkedKeys, infoNode) => {
  emits('onCheck', { checkedKeys, infoNode });
};
</script>
<style lang="scss">
.tree-data-custom .ant-tree-switcher {
  --tw-text-opacity: 1;
  color: rgba(157, 157, 157, var(--tw-text-opacity));
}
.tree-data-custom .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #b5122e !important;
  border-color: #b5122e !important;
}
.tree-data-custom .ant-tree-checkbox {
  margin-left: 10px;
  margin-right: 10px;
}
.tree-data-custom .ant-tree-checkbox .ant-tree-checkbox-inner {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
}
.tree-data-custom .ant-tree-node-content-wrapper:hover {
  background-color: unset;
}
.tree-data-custom .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: #b5122e;
}
</style>
